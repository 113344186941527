import { queries } from "../../../breakpoints"
import styled from 'styled-components';

export const StyledResourceCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;

      @media (${queries.max_break_sm}) {
        > img {
          max-width: 100%;
        }
      }
   
`;


export const Preheader = styled.h3`
    font-weight: bold;
    text-transform: uppercase;
    color: #aeaeae;
    letter-spacing: 4.14px;
    font-size: clamp(var(--fontSize-1), 1.15vw, 3rem);
    margin: 0;
`;

export const Headline = styled.h3`
    font-family: var(--scandia-fontFamily); 
    
    margin-bottom: 30px;
    font-size:clamp(var(--fontSize-2), 3.5vw, 15px);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 3.6666667461395264px;
    text-align: left;
`;

export const Subheadline = styled.h1`
    font-family: var(--scandia-fontFamily); 
    
    font-weight: bold;  
    max-width: 644px;//484px;
    font-size: clamp(var(--fontSize-6), 3.5vw, 45px);
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0px;
    text-align: left;

  @media (${queries.max_break_sm}) {
    text-align: center;
    line-height: 50px;
  }
`;


export const SearchResourceCenter = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    margin-top: 44px;
    margin-bottom: 44px;
    max-width: 288px;
    
    form {    
        display: flex;
        
        .resourceFormSearch{
            display: flex;
            width: 288px;
            
            button{
                background: none;
                border: none;
                outline: none;
                
                position: absolute;
                top: 17px;
                right: 15px;
                width: 15px;
                height: 15px;
                
                svg{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
            }
            
            
            
            input{
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #CAC3FA;
                border-radius: 24px;
                padding: 14px 24px;
                width: 100%;
                transition: all .24s;
                
                font-family: var(--scandia-fontFamily);
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: #000000;
            
                &::placeholder{ 
                    color: #AEB3BB;
                }
                &:focus{
                    outline: none;
                    border: 2px solid var(--color-primary);
                }
            }
        
        }
    
    } 
`;


export const Troubleshooting = styled.div`
    margin-top: 30px;
    font-family: var(--scandia-fontFamily);
    font-size: clamp(var(--fontSize-2), 3.5vw, 18px);
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left; 
    max-width: 516px;
    a{
        margin-left: 4px;
        font-family: var(--scandia-fontFamily);
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        color: var(--color-primary);
    }

      @media (${queries.max_break_sm}) {
        text-align: center;
      }
`;

export const Container = styled.div`
    min-height: 558px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
    z-index: 2;
    @media (${queries.max_break_sm}){
        padding:50px 25px 0px;
        flex-direction: column;
    }
  ${( mobileSwitchEarlier ) => {
    if( mobileSwitchEarlier ) {
      return `
            @media (max-width: 1000px){ 
            
              padding:50px 25px 0px;
              flex-direction: column;
              > img{
                display:none;
              }
              h3, h1{
                text-align: center;
                line-height: 125%;
              }
              h1{
                font-size: 32px;
              }
              > div > div{
                text-align: center;
                > div{
                
                  justify-content: center;
                }
              }
            }
            `
    }
  }}
`;

export const Illustration = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    height: 100%;
    object-fit: contain;
    max-width: 768px;  
    @media (${queries.max_break_sm}){
        display:none;
    }
`;

export const Content = styled.div`
    /*max-width: 516px;*/
    padding: 40px 0%;
    margin-left: 10vw;
    p{
        font-family: var(--scandia-fontFamily);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }
    form {
        max-width: 288px !important;
    }

    @media (${queries.max_break_sm}) {
        margin-bottom: 20px;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
      
        h1, h3{
          text-align: center;
        }
    }
    @media (max-width: 1300px) {
        /*margin-left: 5vw;*/ 
        max-width: 480px;
    }
    @media (min-width: 1001px) and (max-width: 1190px) {
        max-width: 340px;
    }
`;

export const RightIllustration = styled( Illustration )`
  justify-self: end;
`;
