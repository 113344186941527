import React from 'react';
import { StyledResourceFeaturedTags, Headline, FeaturedArticlesWrapper } from "./ResourceFeaturedTags.styles";
import PropTypes from "prop-types";

const ResourceFeaturedTags = ( props ) => {

    const arrowLink = () => {
        return (
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9914 5.92545L14.9754 7.89162L12.9914 9.86575L7.7194 15.1115L5.7434 13.1374C5.7434 13.1374 8.2634 10.8528 9.9834 9.30057H0.575397V6.5145H10.0154C8.3114 4.97022 5.7834 2.68565 5.7834 2.68565L7.7594 0.711517L12.9914 5.92545Z" fill="#6B6B83"/>
            </svg>
        )
    };

    const articles = [
        {
            title: "Getting Started on Your Corporate Account",
            url: "https://corporate.grubhub.com/resource/signing-in/"
        },
        {
            title:"Group Orders",
            url: "https://corporate.grubhub.com/resource/how-to-place-a-group-order-admin/"
        },
        {
            title:"Creating Meal Credits",
            url: "https://corporate.grubhub.com/resource/meal-credits/"
        },
        {
            title: "Manual Employee Edits",
            url: "https://corporate.grubhub.com/resource/uploading-employees-manually/"
        },
        {
            title: "Bulk Employee Edits",
            url: "https://corporate.grubhub.com/resource/uploading-employees-in-bulk/"
        },
        {
            title:"How to Create an STO",
            url: "https://corporate.grubhub.com/resource/team-orders/"
        },
    ];

    return (
        <StyledResourceFeaturedTags style={{ background: props.background, color: props.textColor, ...props.style }} hasChildren={props.children}>
            <div className={"content-wrapper"}>
                <Headline>{props.headline}</Headline>
                <FeaturedArticlesWrapper>
                    {
                        articles.map( ( article ) => {

                            return (
                                <a href={article.url} target={"_parent"}>{article.title}{arrowLink()}</a>
                            )
                        } )
                    }
                </FeaturedArticlesWrapper>
            </div>
        </StyledResourceFeaturedTags>
    )
};


ResourceFeaturedTags.propTypes = {
    headline: PropTypes.string

};

ResourceFeaturedTags.defaultProps = {
    headline: ""
};

export default ResourceFeaturedTags;
