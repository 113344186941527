import React, { useState, useEffect, useRef } from 'react';
import { ResourcesCard, ResourcesCardsWrapper, StyledResourceCenterTabs, TabsContent, TabsContentItem, TabsHeader, TabsHeaderItem, TabsWrapper } from "./ResourceCenterTabs.styles";
import { useStaticQuery, graphql } from "gatsby";
import { buildTopicHierarchy, predefinedSortSubTopics } from "../../../templates/resourcesHelperFunctions";


const ResourceCenterTabs = ( props ) => {
    const rawData = useStaticQuery( graphql`
    query ResourceTopicsQuery {
      allTopics: allWpTopic {
        nodes {
          id
          name
          description
          parentId
        }
      }
    }
  ` );

    const [ topicHierarchy, setTopicHierarchy ] = useState( () => buildTopicHierarchy( rawData.allTopics.nodes ) );
    const [ cards, setCards ] = useState( [] );


    const updateCards = ( topicArray ) => {
        let newCards = [];
        topicArray.forEach( ( subTopic ) => {
            newCards.push( {
                title: `${subTopic.name}`,
                description: subTopic.description,
                link: `/resources/?resource_type=${subTopic.name}`,
                imageSrc: subTopic.imageSource,
                linkText: "See all",
                subTopics: subTopic.subTopics
            } );
        } );
        return newCards;
    }

    useEffect( () => {
        if( topicHierarchy && cards.length === 0 ) {
            setCards( updateCards( topicHierarchy ) );
        }
    }, [ topicHierarchy ] );

    const tabItemClick = ( {target, parentElement} ) => {
        if( !target.matches('.active') ){
            var _active = target.parentElement.querySelector( '.active' );
            if( _active !== null ) {
                _active.classList.remove( 'active' );
            }
            target.classList.add('active');
            window.location.hash = '-' + target.id;
            scrollHashElementIntoView();
            tabShowContent(Array.prototype.indexOf.call( target.parentElement.children, target ));
        }
    }
    const cardClick = ( name, parentTopic ) => {
        window.location.href = `/resources/?resource_parent=${encodeURIComponent( parentTopic )}&resource_type=${encodeURIComponent( name )}`;
    }
    const tabShowContent = ( activeIndex ) =>{
        var tabContent = document.getElementById( 'res-tab-content' ),
            _content = tabContent.querySelector( '.active' );
        if( _content !== null ) {
            _content.classList.remove( 'active' );
        }
        tabContent.children[activeIndex].classList.add('active');
    }

    function tabResourcesWrapperContent ( topicName, subTopics) {
        const renderHTMLArray = [];
        const tempHTMLArray = [];

        if( subTopics.length === 2 && subTopics[0].name === 'Self-Service Accounts' ){
            let temp = subTopics[0];
            subTopics[0] = subTopics[1];
            subTopics[1] = temp;
        }


        renderHTMLArray.push(

            subTopics.map( ( subTopic, i ) => {

                    const secondLevel = subTopic.subTopics && subTopic.subTopics.length > 0;
                    const subTopicHTML = [];

                    if( secondLevel ) {
                        subTopic.subTopics = predefinedSortSubTopics( subTopic.name, subTopic.subTopics, 'name' );
                    }

                    ( secondLevel ) ? (
                        subTopicHTML.push(
                            <>
                                <h4>{subTopic.name}</h4>
                                <p className={"spacing-top"}>{subTopic.description}</p>
                                <ResourcesCardsWrapper>
                                {
                                    subTopic.subTopics.map( ( childSubTopic, i ) => {
                                        return (
                                            <ResourcesCard onClick={( e ) => cardClick( childSubTopic.name, topicName)}>
                                                <img src={childSubTopic.imageSource}/>
                                                <h5>{childSubTopic.name}</h5>
                                                <p>{childSubTopic.description}</p>
                                                <a href="#" className="card-link">Learn more {arrowLink()}</a>
                                            </ResourcesCard>
                                        )
                                    } )
                                }
                                </ResourcesCardsWrapper>
                            </>
                        )
                    ) : (
                        tempHTMLArray.push(
                            <>
                                <ResourcesCard onClick={( e ) => cardClick( subTopic.name, topicName )}>
                                    <img src={subTopic.imageSource}/>
                                    <h5>{subTopic.name}</h5>
                                    <p>{subTopic.description}</p>
                                    <a href="#" className="card-link">Learn more {arrowLink()}</a>
                                </ResourcesCard>
                            </>
                        )
                    )
                    return (
                        <>{subTopicHTML}</>
                    )
                }
            )
        )

        renderHTMLArray.push( (
            <>
                <ResourcesCardsWrapper>
                    {
                        tempHTMLArray
                    }
                </ResourcesCardsWrapper>
            </>
        ))
        return (
                   <>
                   {
                       renderHTMLArray
                   }
                   </>
        )
    }

    const arrowLink = () => {
        return (
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9914 5.92545L14.9754 7.89162L12.9914 9.86575L7.7194 15.1115L5.7434 13.1374C5.7434 13.1374 8.2634 10.8528 9.9834 9.30057H0.575397V6.5145H10.0154C8.3114 4.97022 5.7834 2.68565 5.7834 2.68565L7.7594 0.711517L12.9914 5.92545Z" fill="#6B6B83"/>
            </svg>
        )
    };

    const [ refSet, setTabsRef ] = useState( false );
    const tabsRef = useRef( null );
    const scrollHashElementIntoView = () =>{
        let map = getTabRefsMap(),
            refNodeProps = {},
            urlHash = getDecodedLocationHash();
        if( urlHash !== '' ) {
            const node = map.get( urlHash );
            if( node ){
                refNodeProps.el = node;
            }
        } else {
            refNodeProps.id = 0;
        }
        changeActiveTab( refNodeProps );
    }

    function getDecodedLocationHash(){
        if( window.location.hash !== '' ) {
            let hashId = decodeURIComponent( window.location.hash.replace( '#-', '' ).toLowerCase() );
            hashId = hashId.replace( ' ', '-' );

            return hashId;
        }
        return '';

    }

    function changeActiveTab( args = {} ){
        let idx = 0,
            map = getTabRefsMap();
        map.forEach( ( value, key ) => {
            if( idx === args.id || args.el === value ) {
                tabShowContent( idx );
                value.classList.add( 'active' );
                document.body.scrollIntoView(true);
            } else {
                value.classList.remove( 'active' );
            }
            idx++;
        } )
    }

    function getTabRefsMap() {
        if( !tabsRef.current ) {
            // Initialize the Map on first usage.
            tabsRef.current = new Map();
        }
        return tabsRef.current;
    }


    if( refSet ){
        scrollHashElementIntoView();
    }

    return (
        <StyledResourceCenterTabs style={{ background: props.background, color: props.textColor, ...props.style }} hasChildren={props.children}>
            <TabsWrapper>
                <TabsHeader>
                     {
                         cards.map( ( card, i ) => {
                             let cardId = card.title.replace( ' ', '-' ).toLowerCase();
                             return <div id={cardId} ref={( node) => {
                                 const map = getTabRefsMap();
                                 if( node ) {
                                     map.set( cardId, node );
                                 } else {
                                     map.delete( cardId );
                                 }
                                 if(cards.length === i+1){
                                     setTabsRef(true);
                                 }
                             }} className={`tab-item ${i === 0 ? ' active' : ''}`} onClick={( e ) => tabItemClick( e )}>{card.title}</div>
                         })
                     }
                </TabsHeader>
                {

                }
                <TabsContent id="res-tab-content">
                    {
                        cards.map( ( card, i ) => {

                            if( card.title === 'Employees' ){
                                card.subTopics = predefinedSortSubTopics( card.title , card.subTopics, 'name' );
                            }

                            return (
                                <TabsContentItem className={`tab-content ${i === 0 ? ' active' : ''}`}>
                                    <p>{card.description}</p>
                                    {
                                        tabResourcesWrapperContent( card.title, card.subTopics )
                                    }
                                </TabsContentItem>
                            )
                        } )
                    }
                </TabsContent>
            </TabsWrapper>
        </StyledResourceCenterTabs>
    )
};

export default ResourceCenterTabs;
