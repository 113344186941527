import { queries } from "../../../breakpoints"
import styled from 'styled-components';

export const StyledResourceFeaturedTags = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    flex-direction: column;
    width: 100%;
    
    min-height: 200px;  
    padding: 0 0 64px 0;
    
    .content-wrapper{
      max-width: 960px;
      display: flex;
      flex-direction: column;
    }

      @media (${queries.max_break_sm}) { 
        padding: 48px 24px;
      }
      @media (max-width: 1000px) { 
        padding: 48px 24px;
        > div{
          text-align: center;
        }
      }
`;
export const Headline = styled.h2`
    font-weight: bold;
    margin-bottom:20px;
`;

export const FeaturedArticlesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  
  a{
    display: flex;
    align-items: center;
        
    font-family: var(--scandia-fontFamily);
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-right: 50px;
    margin-top: 30px;
    
    svg{
      margin-left: 4px;
      transform: translateX(0px);
      transition: all .24s;
    }
    &:hover{
      svg{
        transform: translateX(4px);
      }
    }

    @media (${queries.max_break_sm}) {
      margin-right: 0;
    }
  }

  @media (${queries.max_break_sm}) {
    flex-direction: column;
  }
`;
