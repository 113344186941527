import { queries } from "../../../breakpoints"
import styled from 'styled-components';

export const StyledResourceCenterTabs = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    
    min-height: 400px;
    padding: 64px 0;

  @media (${queries.max_break_sm}) {
    padding: 48px 0px;
  }
`;

export const TabsWrapper = styled.div`
    display: flex;
    max-width: 960px;
    width: 100%;
    flex-direction: column;
`;

export const TabsHeader = styled.div`
    display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 2px solid #D4D4DB;

  @media (${queries.max_break_sm}) {
    max-width: 78%;
    margin: 0 auto;
  }
  div.tab-item{
    position: relative;
    display: flex;
    min-width: 146px;
    padding: 10px;
    font-family: var(--scandia-fontFamily);
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    justify-content: center;
    color: #000000;
    cursor: pointer;

    &.active {
      font-weight: 700;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 4px;
        left: 0px;
        bottom: -2px;
        background: #572AF8;

      }
    }

    @media (${queries.max_break_sm}) {
      font-size: 18px;
      line-height: 20px;
      align-items: flex-end;
    }
  }
`;
export const TabsHeaderItem = styled.div`
    position: relative;
    display: flex;  
    min-width: 146px;
    padding: 10px;
    font-family: var(--scandia-fontFamily);
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    justify-content: center;
    color: #000000;
    cursor: pointer;
    
    &.active{
        font-weight: 700;
        &::before{
            position: absolute;
            content: "";
            width: 100%;
            height:4px;
            left: 0px;
            bottom: -2px;
            background: var(--color-primary);

        }
    }

  @media (${queries.max_break_sm}) {
    font-size: 18px;
    line-height: 20px;
    align-items: flex-end;
  }
`;
export const TabsContent = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row;
    padding-top: 24px;
    padding-bottom: 24px;
`;
export const TabsContentItem = styled.div`
    display: none; 
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    
    &.active{
        display: flex;
    }
    p{
        font-family: var(--scandia-fontFamily);
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: left;

        color: #000000;
      
        &.spacing-top{
          margin-top: 48px;
        }

    }
    h4{
        margin-top: 60px;
        font-family: var(--scandia-fontFamily);
        font-size: 36px;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: 0px;
        text-align: center;

    }

    @media (${queries.max_break_sm}) {
        align-items: center;
        p{ 
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
        
              max-width: 78%; 
              text-align: center;
              max-width: 275px;
        }
        h4{
            margin-top: 48px; 
          max-width: 78%;
          text-align: center;
          
        }
    }
`;
export const ResourcesCardsWrapper=styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    @media (${queries.max_break_sm}) {
        flex-direction: column;
        align-items: center;
        
    }
`;
export const ResourcesCard=styled.div`
    display: flex;
    width: 33%;
    max-width: 300px;
    height: 300px;
    flex-direction: column;
    align-items: center;
    padding: 26px 34px;
    
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(207, 198, 198, 0.5);
    border-radius: 10px;
    cursor: pointer;
    transition: all .24s;
    
    
    &:hover{ 
        box-shadow: 0px 8px 16px rgba(207, 198, 198, 0.8);
    }
    
    &:nth-child(n+4){
        margin-top: 30px;
    }    
    
    img{
        max-width: 55px;
        width: auto;
        object-fit: contain;
    }
    h5{
        margin-top: 20px;
        margin-bottom: 0px;
        font-family: var(--scandia-fontFamily);
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;

    }
    p{
        margin-top: 12px;
        margin-bottom: 12px;
        font-family: var(--scandia-fontFamily);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
    }
    a.card-link{
        font-family: var(--scandia-fontFamily);
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px; 
        cursor:pointer;
        color: var(--color-primary);    
        margin-top: auto;

      svg { 
        transform: translateX(0px);
        transition: all .24s;
      }

      &:hover {
        svg {
          transform: translateX(4px);
        }
      }
    }


    @media (${queries.max_break_sm}) { 
        width: 100%;
        max-width: 275px;
        height: 325px;

      &:nth-child(n+4) {
        margin-top: 0px;        
      }
      &:not(:first-child){
        margin-top: 30px;
      }
    }
`;
