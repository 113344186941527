import React, { useContext } from 'react';
import { AnalyticsContext } from "../../../context";
import { Container, Content, Headline, Preheader, Subheadline, Troubleshooting, RightIllustration, StyledResourceCenter, SearchLearningCenter, SearchResourceCenter } from "./ResourceSearchHeader.styles";
import PropTypes from "prop-types";
import { SearchBar } from "../../page-specific/Blog/BlogSidebar/search";
import { SearchResourceForm } from "./search-resource";
import ResearchSearch from "../ResourceSearch/ResourceSearch";
import { MobileIllustration } from "../IllustrationHeader/IllustrationHeader.styles";

const ResourceSearchHeader = ( props ) => {
    const { handleTealiumEvent } = useContext( AnalyticsContext );
    return (
        <StyledResourceCenter style={{ background: props.background, color: props.textColor, ...props.style }}hasChildren={props.children} mobileSwitchEarlier={props.mobileSwitchEarlier}>
             <Container mobileSwitchEarlier={props.mobileSwitchEarlier}>
                <Content>
                    {props.preheader && <Preheader>{props.preheader}</Preheader>}
                    {props.headline && <Headline style={{ color: props.headlineColor }}>{props.headline}</Headline>}
                    {props.subheadline && <Subheadline style={{ color: props.subheadlineColor }}>{props.subheadline}</Subheadline>}
                    {
                        props.description &&
                        <p style={{ color: props.descriptionColor }}>{props.description}</p>
                    }
                    <ResearchSearch
                        searchSpacingTop={props.searchSpacingTop}
                    />
                    {props.troubleshooting && <Troubleshooting style={{ color: props.troubleshootingColor }}>
                        {props.troubleshooting}
                        <a href="https://corporate.grubhub.com/resource/how-to-apply-a-meal-credit/">How to Apply a Meal Credit</a>,
                        <a href="https://corporate.grubhub.com/resource/signing-in-2/">Signing in</a>,
                        <a href="https://corporate.grubhub.com/resource/individual-orders-2/">Individual Orders</a>
                    </Troubleshooting>}
                </Content>
                <RightIllustration src={props.rightSideIllustration}/>
             </Container>

                <MobileIllustration src={props.mobileIllustration} mobileSwitchEarlier={props.mobileSwitchEarlier }  />
        </StyledResourceCenter>
    )
};

ResourceSearchHeader.propTypes = {
    preheader: PropTypes.string,
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    description: PropTypes.string,
    ctaLink: PropTypes.string,
    ctaText: PropTypes.string,
    background: PropTypes.string,
    backgroundImage: PropTypes.string,
    searchSpacingTop: PropTypes.string,
    textColor: PropTypes.string,
    subheadlineColor: PropTypes.string,
    headlineColor: PropTypes.string,
    descriptionColor: PropTypes.string,
    troubleshootingColor: PropTypes.string,
    style: PropTypes.shape( {} ),
    leftSideIllustration: PropTypes.string,
    rightSideIllustration: PropTypes.string,
    mobileIllustration: PropTypes.string,
    mobileSwitchEarlier: PropTypes.bool
};

ResourceSearchHeader.defaultProps = {
    preheader: null,
    headline: null,
    subheadline: null,
    description: null,
    ctaLink: null,
    ctaText: "Get started",
    background: "#F9F9F9",
    backgroundImage: null,
    searchSpacingTop: "20px",
    textColor: "#000000",
    subheadlineColor: "var(--color-text-light)",
    headlineColor: "var(--color-secondary)",
    descriptionColor: "var(--color-secondary)",
    troubleshootingColor: "#000000",
    style: null,
    leftSideIllustration: null,
    rightSideIllustration: null,
    mobileIllustration: null,
    mobileSwitchEarlier: true
};

export default ResourceSearchHeader;
