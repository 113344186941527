import { Card, CardContent, CardMedia } from "@material-ui/core";
import CtaSection from "../components/sections/CtaSection";
import Layout from "../components/global/Layout";
import React from "react";
import { queries } from "../breakpoints";
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import { buildTopicHierarchy, shouldNavigateAway } from "../templates/resourcesHelperFunctions";
import ResourceCenterHeader from "../components/global/ResourceCenterHeader/ResourceCenterHeader";
import ResourceCenterTabs from "../components/global/ResourceCenterTabs/ResourceCenterTabs";
import ResourceFeaturedTags from "../components/global/ResourceFeaturedTags/ResourceFeaturedTags";
import ResourceSearchHeader from "../components/global/ResourceSearchHeader/ResourceSearchHeader";

const LearningCenter = ({ location }) => {
  const rawData = useStaticQuery(graphql`
    query WordPressTopicsQuery {
      allTopics: allWpTopic {
        nodes {
          id
          name
          description
          parentId
        }
      }
    }
  `);

  const [topicHierarchy, setTopicHierarchy] = React.useState(buildTopicHierarchy(rawData.allTopics.nodes));
  const [cards, setCards] = React.useState([]);

  const updateCards = (topicArray) => {
    let newCards = [];
    topicArray.forEach((subTopic) => {
      //console.log( 'Subtopic.name - ' + subTopic.name );
      newCards.push( {
        title: `${subTopic.name}`,
        description: subTopic.description,
        link: `${location.origin}/resources/?resource_type=${subTopic.name}`,
        imageSrc: subTopic.imageSource,
        linkText: "See all",
        action: shouldNavigateAway( subTopic ) ?
            () => {
              console.log( 'Change window location' );
              //window.location.href = `/resources/?resource_type=${subTopic.name}`
            } :
            () => {
              console.log( 'Set update cards' );
              setCards( updateCards( subTopic.subTopics ) )
            },
      } );
    } );
    return newCards;
  }

  React.useEffect(() => {
    if (topicHierarchy && cards.length === 0) {
      setCards(updateCards(topicHierarchy));
    } else {
      rawData.allTopics.nodes.forEach(element => {
        element.used = false;
      });
      setTopicHierarchy(buildTopicHierarchy(rawData.allTopics.nodes));
    }
  },[topicHierarchy, rawData]);

  return (
      <Layout
          location={location}
          title={"Learning Center — Grubhub Corporate"}
          description={"Questions about how a Grubhub corporate account works? Check out our resources center where we provide the information account admins and employees need including step-by-step instructions for using a corporate account."}
          className="learning-center-page"
          utag_data={{
            pageGroup: "",
            subGroup: "",
            pageName: "",
            brand: "grubhub"
          }}
      >


      <ResourceSearchHeader
          headline="LEARNING CENTER"
          subheadline="Learn how to use your Grubhub Corporate Account"
          troubleshooting="Common troubleshooting topics:"
          ctaLink="/get-started/"
          backgroundColor="#F9F9F9"
          searchSpacingTop="44px"
          rightSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1664389214/HERMES/2022/B2B/GHCORP/B2B-Corp-20220908-CLC-Updates/Homepage/Desktop/DSK_CorpAdmin_Header.png"
          mobileIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1664389293/HERMES/2022/B2B/GHCORP/B2B-Corp-20220908-CLC-Updates/Homepage/Mobile/Header_Mobile.png"
          mobileSwitchEarlier={true}
      />
      <ResourceCenterTabs
          backgroundColor="#ffffff"
      ></ResourceCenterTabs>
      <ResourceFeaturedTags
          headline="Featured Articles"
      />
      <CtaSection headline="Need to get in touch?" ctaText={"Contact us"} backgroundColor={"#F6F6F6"}/>
    </Layout>
  );
}

export default LearningCenter


const TitleContainer = styled.div`
  margin: 3rem 0 2rem;
`

const Title = styled.h1`
  text-transform: uppercase;
  color: var(--color-text);
  font-size: var(--fontSize-1);
  font-weight: 300;
  letter-spacing:3.67px;
  
`

const SubTitle = styled.h3`
  color: var(--color-text);
  font-size: var(--fontSize-8);
  font-weight: bold;
  @media(${queries.max_break_sm}){
    font-size: var(--fontSize-4);
  }
`
const CardContainer = styled.div`
  display:flex;
  flex-flow:row;
  padding-bottom: 4rem;
  @media(${queries.max_break_md}){
    flex-flow:column;
  }
`;

const StyledCardMedia = styled(CardMedia)`
    height:230px;
    @media(${queries.max_break_md}){
      height:150px;
    }
`;

const StyledCardContent = styled(CardContent)`
    padding:25px !important;
    flex:1;
    display:flex;
    flex-flow: column;
    justify-content:space-between;
    &:last-child{
        padding-bottom: 25px;
    }
    @media(${queries.max_break_md}){
      padding:25px 15px !important;
    }
`;

const CardTitle = styled.h4`
    font-weight: bold;
    font-size: var(--fontSize-3);
    @media(${queries.max_break_md}){
      font-size: var(--fontSize-2);
    }
    @media(${queries.max_break_sm}){
      font-size: var(--fontSize-1);
    }
`;

const Description = styled.p`  
  min-height: 90px;
  max-height:200px;
  @media(${queries.max_break_md}){
    display:none;
  }
`;

const StyledButton = styled.button`
    margin-top: 20px;
    max-width:120px;
    padding: 10px 30px;
    border-radius: 100px;
    font-weight: bold;
    font-size: var(--fontSize-1);
    display: inline-block;
    text-align: center;
    -webkit-transition: .1s all;
    transition: .1s all;
    background-color: var(--color-primary);
    color: white;
    border:none;
    &:hover{
      color:white;
      background-color:var(--color-primary-dark);
    }
    &:active{
      background-color:var(--color-primary-darker);
    }
    @media(${queries.max_break_md}){
      max-width:100px;
      padding: 5px 15px;
    }
`;

const StyledCard = styled.div`
    flex:1;
    margin: 20px;
    transition: all .2s;
    .MuiPaper-root{
        height:100%;
        display:flex;
        flex-flow:column;
      font-family: Scandia Regular !important;
    }
    :hover{
        transform: scale(1.05);
    }
    :active{
        transform: scale(.95);
    }
`
